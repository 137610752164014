<template>
  <div class="is-flex columns content-page">
    <left-panel-security/>
    <div class="column is-7">
      <div class="centered is-flex-direction-column py-6">
        <div dark-color bold class="login-main-text">{{ $t('forgot_password') }}</div>
        <div class="form-login centered is-flex-direction-column">
          <b-field>
            <b-input :placeholder="$t('email')"
                     type="email"
                     required
                     :validation-message="$t('invalid_email')"
                     v-model="email"
                     icon="account"
                     icon-clickable>
            </b-input>
          </b-field>

          <div class="is-flex is-flex-direction-column">
            <b-button @click="forgotPassword" class="btn btn-insert" type="is-primary">{{ $t('send') }}</b-button>
            <router-link :to="{name:'login'}">
              <b-button class="btn btn-back">
                {{ $t('go_back') }}
              </b-button>
            </router-link>

          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {mapMutations, mapActions} from "vuex";
import LeftPanelSecurity from "@/components/structure/leftPanelSecurity";

export default {
  name: "loginComponent",
  components: {LeftPanelSecurity},
  data() {
    return {
      email: null,
    }
  },
  methods: {
    ...mapMutations({
      setDash: 'SET_IS_DASH',
      setLoginPage: 'SET_IS_LOGIN'
    }),
    ...mapActions({
      doForgot: 'FORGOT_PASSWORD',
      goTo: 'GO_TO'
    }),
    forgotPassword() {
      this.doForgot(this.email)
          .then(() => {
            this.goTo('resetPassword')
          })
    },
  },
  created() {
    this.setDash(false)
    this.setLoginPage(true)
  }
}
</script>

<style scoped lang="scss">


/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
  /* insert styles here */
  .login-main-text {
    font-size: 40px;
    margin-top: 80px;
  }
  .login-help-text {
    font-size: 12px;
  }
}

</style>